.navbar-nav .nav-link {
  color: white;
  position: relative;
}

.navbar-nav .nav-link.active {
  color: rgb(225, 173, 7) !important;
  text-decoration: none !important;
}

.navbar-nav .nav-link::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: rgb(225, 173, 7);
  transition: width 0.3s;
  position: absolute;
  bottom: -1px;
  left: 0;
}

.navbar-nav .nav-link:hover::after {
  width: 100%;
}

.navbar-nav .nav-link:hover {
  color: rgb(225, 173, 7);
}

.navbar-nav .nav-link.active::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background: rgb(225, 173, 7);
  position: absolute;
  bottom: -1px;
  left: 0;
}
