@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Poppins', sans-serif; /* Set Poppins as the default font family */
  background-color: black;
  color: white;
}

.App {
  text-align: center;
}