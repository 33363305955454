.logo-splash {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    opacity: 1;
    transform: translateY(0);
    transition: opacity 1s ease-out, transform 1s ease-out; /* Add transition for fade-out and slide-up effect */
    background-color: rgba(0, 0, 0, 0.95);
}

.logo-splash.fade-out {
    opacity: 0;
    transform: translateY(-100%); /* Slide up */
}

.logo-splash-image {
    width: auto;
    transition: all 0.3s ease-in-out;
    max-height: 30vh;
    object-fit: contain;
}

/* Responsive adjustments */
@media (max-width: 640px) {
    .logo-splash-image {
        max-height: 20vh;
    }
}

@media (max-width: 480px) {
    .logo-splash-image {
        max-height: 15vh;
    }
}

@media (min-width: 1024px) {
    .logo-splash-image {
        max-height: 35vh;
    }
}
