.home-container {
    display: flex;
    height: 100vh;
    background-color: black;
    color: white;
}

.text-section {
    flex: 1;
    padding: 20px;
}

.image-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-section img {
    max-width: 100%;
    height: auto;
    border-radius: 50%;
}

.fa:hover {
    color: #e1ad07;
}

.rotate-180 {
    transform: rotateY(180deg);
}

.flip-180 {
    transform: rotateX(180deg);
}

.pan-top {
    object-position: top;
}
